export const S_DEVICE = 600;
export const M_DEVICE = 1133;
export const XL_DEVICE = 1800;

export const NB_THEMATICS_TO_SHOW = 99;
export const NB_QUESTIONS_TO_SHOW = 99;

export const MIN_GIFT_CARD = 1;
export const MAX_GIFT_CARD = 3000;

export const GLOBAL_ALERTS_RETRY_TIME = 5;
export const PARTNERS_TO_SHOW_POT_PAGE = 6;
export const PARTNERS_CATEGORIES_WIDTH = 1450;
export const DEV_INTEGRATION_SUBDOMAINS = ['frontend', 'devfrontend', 'frontend2', 'frontend3'];
