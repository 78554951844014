import React from 'react';

import DOMPurify from 'isomorphic-dompurify';

import css from './Text.module.scss';

type TextProps = {
    children?: React.ReactNode;
    variant?:
        | 'body_00'
        | 'body_01'
        | 'body_02'
        | 'body_03'
        | 'caption_00'
        | 'caption_01'
        | 'caption_02'
        | 'caption_03'
        | 'button_small'
        | 'button_default'
        | 'button_large'
        | 'tagline'
        | 'mini_label'
        | 'label'
        | 'hint'
        | 'warning'
        | 'footer'
        | 'caption_semi_bold'
        | 'caption_bold_small'
        | 'caption_bold_medium'
        | 'caption_bold';
    color?: string;
    customClass?: string;
    customStyle?: any;
    tag?: string;
    html?: boolean;
    htmlText?: string | any;
};

const Text = ({ variant, children, color, customClass, customStyle, tag, html, htmlText }: TextProps) => {
    const textStyle = () => {
        let className = '';
        color && (className += ' ' + css[color]);
        variant === 'body_00' && (className += ' ' + css.body_00);
        variant === 'body_01' && (className += ' ' + css.body_01);
        variant === 'body_02' && (className += ' ' + css.body_02);
        variant === 'body_03' && (className += ' ' + css.body_03);
        variant === 'caption_00' && (className += ' ' + css.caption_00);
        variant === 'caption_01' && (className += ' ' + css.caption_01);
        variant === 'caption_02' && (className += ' ' + css.caption_02);
        variant === 'caption_03' && (className += ' ' + css.caption_03);
        variant === 'button_small' && (className += ' ' + css.button_small);
        variant === 'button_default' && (className += ' ' + css.button_default);
        variant === 'button_large' && (className += ' ' + css.button_large);
        variant === 'tagline' && (className += ' ' + css.tagline);
        variant === 'mini_label' && (className += ' ' + css.mini_label);
        variant === 'label' && (className += ' ' + css.label);
        variant === 'hint' && (className += ' ' + css.hint);
        variant === 'warning' && (className += ' ' + css.warning);
        variant === 'footer' && (className += ' ' + css.footer);
        variant === 'caption_bold' && (className += ' ' + css.caption_bold);
        variant === 'caption_semi_bold' && (className += ' ' + css.caption_semi_bold);
        variant === 'caption_bold_small' && (className += ' ' + css.caption_bold_small);
        variant === 'caption_bold_medium' && (className += ' ' + css.caption_bold_medium);

        return className;
    };
    if (tag && tag === 'span') {
        return (
            <span className={`${textStyle()} ${customClass || ''}`} style={customStyle || null}>
                {children}
            </span>
        );
    }
    if (html) {
        return (
            <div
                className={`${textStyle()} ${customClass || ''}`}
                style={customStyle || null}
                // @ts-ignore
                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(htmlText, { ADD_ATTR: ['target'] }) }}
            />
        );
    }

    return (
        <div className={`${textStyle()} ${customClass || ''}`} style={customStyle || null}>
            {children}
        </div>
    );
};

export default Text;
